const mytext = `# Dashboard in Detail
When you first login to eljay.xyz you will be presented with the *Dashboard*. The dashboard ties to give you an overview of the jobs in Eljay depending on your privileges you may see: 

- Jobs today
- Jobs this week
- My paperwork
- My Diary
- Invoice Value
- Paperwork Value

Most of these are fairly self explanatory but we will cover some in more detail:

#### Jobs today
The total number of jobs booked in for the day across the company.

#### Jobs Week
Total number of jobs this week across the company.

#### My Paperwork
The number of completed jobs which you have paperwork outstanding for.

#### My Diary
The number of sites/jobs you have booked in your diary.

#### Invoice Value
The total value of outstanding invoices across the company.

#### Paperwork Value
The total value of **Priced** work with paperwork which is outstanding. Work that has yet to be prices will recorded as zero.

NOTE: **Invoice Value** and **Paperwork Value** are a guide and the accuracy of these values are dependant on how up to date Eljay is.

## Dashboard Graphs
There could be up to three graphs on Eljay these are:
- Outstanding Paperwork by employee
- Site Work Completed for Week
- Value of Outstanding invoices by Customer

#### Outstanding Paperwork by employee
When this is displayed it shows the amount of paperwork which is outstanding grouped by employee hovering a mouse over each segment of the graph shows the users name and the number of jobs outstanding.

#### Site Work Completed for Week
This graph shows the number of site visits that are undergoing this week and the number that have been completed.

#### Value of Outstanding invoices by Customer 
This pie chart shows the value of outstanding invoices split into different customers, this allows you to hover over the chart and see which customers owe the most money.

## Recent Activity
This area of the dashboard shows your recent activity across Eljay website and mobile apps. It will list different events, including:
- Completing paperwork
- Changing job status
- Creating jobs
- Updating jobs

And many more events, more events are added by the development team. `;

export default mytext;