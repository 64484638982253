import React from "react";
import Link from "@material-ui/core/Link";

export default function PageLink(props) {

  return (
      <Link
        display="block"
        variant="body1"
        href={`#${props.link}`}
        key={props.title}
      >
        {props.title}
      </Link>
  );
}
