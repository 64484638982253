const markdown = `# Contracts
A contract is simply a group of jobs which could be part of a contract or just a group of similar jobs for the same **Customer**. 

## New Contract (New)
Creating a new contract only requires you to name the **Contract** and select a **Customer** to link to the contract. 

Once the **Contract** has been created you will be directed to the **Contract Page** page for the contract you created.

## Contract Page
First there are thee heading with information:

### Contract
Under **Contract** is the information which was added during the contract creation:- *Contract Name* and *Customer Name* 

### Details
**Details** shows us who created the contract and when is created. It also shows when the contract was closed and by which user.

>Once a **Contract** is completed it can be closed, all remaining jobs are set to *Cancelled* and the **Contract** is set to inactive 

### Job Counts
**Job Counts** lists the amount of  jobs that are linked under this contract and the amount of completed jobs.

## View Schedule Items (Button)

### Contract Items

>**Contract items** is an idea that came from working with customers using SOR (Schedule of Rates) numbers.

Contract items allows you to create a **Contract Item**. These items can then be added to a job under the contact. These items have a user created *ID*, *Name*, *Comments* and *Value*.  

Once a **Contract Item** has been created materials can be added to the item, these items will then be added to the job each time the item is added. Find out more about materials [here](https://help.eljay.xyz/materials).

### Add Contract Item (New Item Button)
Clicking *New Item* button leads you to the **New Contract Item** page, here three fields are required *Item ID (SOR)*, *Item Name* and *Item Cost*. 

The Item ID (SOR) is just a number, if you are using SOR's on a contract then you can enter them here, a list of the SOR/ID's can be copied and pasted for each job on the job page under the contact tab. More on adding **Job** contact items [here](https://help.eljay.xyz/jobs#contract-items).

### Add Material to Contract Item (Add Material To Item Button)
Adding a material to a **Contract Item** will associate that material with that item and will add that material to each job every time the **Contract Item** is added.

On the **Add Material to Item** page there is a search field, find the material and add the materials and the quantities you would expect the item to consume and the materials will be added to the job every time the item is added. 

Find out more about creating materials and materials and where their used [here](https://help.eljay.xyz/materials)
 
#### Example Contract Item
A heating company creates a contract to install boilers, they getting paid depending on the work required at each property. So they create a contract and then add three **Contract Items**:-
1. Supply and fit boiler
2. Supply and fit 1200mm radiator 
3. Supply ans fit 1800mm radiator

When the heating companies engineer finishes the job they then select the items they completed on that particular job. So say they fitted: 
- 1 x boiler
- 2 x 1200mm radiators
- 3 x 1800mm radiators

Eljay will now knows what was installed and will take the values of the *Boiler*  add to it 2 x *1200mm radiators* and add to that 3 x *1800mm radiators*. Eljay will add up all the items and make pricing the job simpler. 

## Download Report (Button)
When you click download report, the web browser will download a .pdf file of the jobs listed in the table below the **Download Report** button. To produce a report which only shows certain jobs use the sliders and filter filters to produce a list of jobs you require.  These filter items are:

- Show Completed Site Work
  - When selected will show all work where the work on site has been completed.
- Show Cancelled Work
	- This option will only show work that has been cancelled.
- Show Completed Jobs
	- This will show work where the job is completed i.e. site work, priced, invoiced, paperwork completed and paid.
- Show Work Booked In
	- This option will only display work that has been booked in but not yet completed. 
- Min Date
	- When a minimum date is set only **jobs started after** this date will be displayed.
- Max Date
	- Will only show **jobs started before** this date.
- Show all street names
	- This drop down will list street names. When a street is selected will only show jobs which match that street.
- Show all city names
	- Will list all the cities the jobs are in and when selected will only show jobs in that city.

#### Example Filter Jobs

If you wanted to see all the jobs for the week of 16th December to the 20th December which were booked in in Southampton. You would set the filters to the following:

1. *Show Work Booked In* - turn on
2. *Min Date* - 16/12/2019
3. *Max Date* - 20/12/2019
4. *Show all City names* - Southampton

Leaving all the above filters to the default apart from the four above would give you the jobs your are looking for. Clicking on the **Download Report** button would download a report with only those jobs.

## Appointment Letter (Button)
Eljay creates a appointment booking confirmation letter which can be downloaded for each job from the job page [See Appointment Letter Download](https://help.eljay.xyz/jobs#download-appointment-letter-button).

This option allows you to create a template letter for this **Contract** so every job in this contact would have the same appointment letter. From this button this letter template can be altered. The options are:

- Job Window
	- Eljay allows you to add a window for the appointment if set to 2 hours. Eljay will add 2 hours to the site start time. For a job booked at 8am the letter would say arrive between 8am - 10am.
- Letter Reference 
	- The reference for the letter
- Paragraph 1 - 
	- First paragraph of the letter
- Paragraph 2 - 
	- Second paragraph followed by the appointment details
- Paragraph 3 - 
	- Third paragraph
- Sign off - 
	- The sign off for the letter e.g. *We look forward to working with you*

>All empty fields will be omitted from the letter

## Close Contract (Button)
Clicking the **Close Contract** button will set all jobs that are in the contract that have not been completed to *Cancelled* and the contract will be set to inactive. 

The contract will not appear in the list of contract when creating a job.

## Adding a job to a contract
To add a job to a contract when creating a new job or editing a job. There is a field called **Select a contract** this will display a list of open contracts for the selected customer. For more help with jobs see [here](https://help.eljay.xyz/jobs#new-job) 

>If your having trouble finding the contract ensure the correct customer is selected. `;

export default markdown;