import React from "react";
import ReactMarkdown from "markdown-to-jsx";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import { Divider } from "@material-ui/core";
import { grey, green } from "@material-ui/core/colors";

const styles = theme => ({
  listItem: {
    marginTop: theme.spacing(1)
  }
});

const options = {
  overrides: {
    h1: {
      component: props => (
        <div>
          <Typography gutterBottom variant="h4" color="primary" {...props} />
          <Divider style={{ marginBottom: 8 }} />
        </div>
      )
    },
    h2: {
      component: props => (
        <div>
          <Typography gutterBottom variant="h5" color="primary" {...props} />
          <Divider style={{ marginBottom: 8 }} />
        </div>
      )
    },
    h3: {
      component: props => <Typography gutterBottom variant="h6" {...props} />
    },
    h4: {
      component: props => (
        <Typography
          gutterBottom
          variant="subtitle1"
          color="textPrimary"
          style={{ fontWeight: "bold" }}
          {...props}
        />
      )
    },
    p: { component: props => <Typography paragraph {...props} /> },
    a: { component: Link },
    li: {
      component: withStyles(styles)(({ classes, ...props }) => (
        <li className={classes.listItem}>
          <Typography component="span" {...props} />
        </li>
      ))
    },
    blockquote: {
      component: props => (
        <div
          style={{
            backgroundColor: grey[200],
            borderWidth: 2,
            borderColor: green[500],
            borderRadius: 10,
            padding: 8,
            display: "flex",
            flexDirection: "row",
            marginLeft: 40,
            marginTop: 16,
            marginBottom: 16
          }}
        >
          <Typography paragraph color="primary" style={{ paddingRight: 8, paddingLeft: 8 }}>
            NOTE:
          </Typography>

          <Typography paragraph color="textPrimary" {...props} />
        </div>
      )
    }
  }
};

export default function Markdown(props) {
  return <ReactMarkdown options={options} {...props} />;
}
