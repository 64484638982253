import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route } from "react-router-dom";
import "typeface-roboto";
import Container from "@material-ui/core/Container";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import red from "@material-ui/core/colors/red";
import blue from "@material-ui/core/colors/blue";
import yellow from "@material-ui/core/colors/yellow";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import Footer from "./components/footer";
import eljay_logo from "./img/eljay.png";

import Home from "./components/Home";
import Dashboard from "./components/Dashboard";
import Customers from "./components/Customers";
import Contracts from "./components/Contracts";
import Leads from "./components/Leads";

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: yellow,
    error: red,
    // Used by `getContrastText()` to maximize the contrast between the background and
    // the text.
    contrastThreshold: 3,
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2
  }
});

const useStyles = makeStyles(theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: "space-between"
  },
  toolbarTitle: {
    flex: 1
  },
  toolbarTitleImage: {
    flex: 1,
    maxWidth: 100,
    justifySelf: "centre"
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    flexWrap: "wrap"
    // overflowX: "auto"
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0)
  }
}));

const sections = [
  { title: "Dashboard", link: "/dashboard" },
  { title: "Customers", link: "/customers" },
  { title: "Contracts", link: "/contracts" },
  { title: "Leads", link: "/leads" },
  { title: "Quotations", link: "/customers" },
  { title: "Jobs", link: "/customers" },
  { title: "Materials", link: "/customers" },
  { title: "Orders", link: "/customers" },
  { title: "Suppliers", link: "/customers" },
  { title: "Appointments", link: "/customers" },
  { title: "Users", link: "/customers" },
  { title: "My Account", link: "/customers" },
  { title: "My Company", link: "/customers" },
  { title: "Vehicles", link: "/customers" },
  { title: "Eljay Site", link: "/customers" }
];

function App(props) {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <CssBaseline />
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            {/* <Button size="small">Subscribe</Button> */}
            {/* <Typography
              component="h2"
              variant="h5"
              color="inherit"
              align="center"
              noWrap
              className={classes.toolbarTitle}
            >
              Eljay Help
            </Typography> */}
            <img
              src={eljay_logo}
              alt="Eljay Logo"
              className={classes.toolbarTitleImage}
            />
            <Button size="small">Search</Button>

            {/* <Button variant="outlined" size="small">
              Sign up
            </Button> */}
          </Toolbar>
          <Toolbar
            component="nav"
            variant="dense"
            className={classes.toolbarSecondary}
          >
            {sections.map((section, index) => (
              <Link
                color="inherit"
                noWrap
                key={index}
                variant="body2"
                href={section.link}
                className={classes.toolbarLink}
              >
                {section.title}
              </Link>
            ))}
          </Toolbar>
          <Route exact path="/" component={Home} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/customers" component={Customers} />
          <Route exact path="/contracts" component={Contracts} />
          <Route exact path="/leads" component={Leads} />
        </Container>
        {/* Footer */}
        <Footer />
        {/* End of Footer */}
      </Router>
    </ThemeProvider>
  );
}

export default App;
