import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import { Box, Grid } from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";
import fbLogo from "../img/fb-logo.png";

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0, 0, 0)
  },
  copyright: {
    backgroundColor: blue[700],
    padding: theme.spacing(2, 0)
  },
  mainFooter: {
    marginBottom: theme.spacing(6)
  },
  img: {
    marginTop: theme.spacing(1)
  }
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Footer */}
      <footer className={classes.footer}>
        <Container maxWidth="lg" className={classes.mainFooter}>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <Box>
              <Typography variant="h5" gutterBottom>
                L&J Electrical Services Ltd
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Unit 57C, City Business Park
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Somerset Place
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Stoke
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Plymouth
              </Typography>
              <Typography variant="body2" color="textSecondary">
                PL3 4BB
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" gutterBottom>
                Opening Times
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Monday to Friday: 8:00 - 16:30
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Saturday: 8:00 - 16:00
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {"Call us: "}
                <Link color="inherit" href="tel:01752560901">
                  01752 560 901
                </Link>
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {"Email us: "}
                <Link
                  color="inherit"
                  href="mailto:info@lj-elec.co.uk?Subject=Hello%20from%20your%20website..."
                >
                  info@lj-elec.co.uk
                </Link>
              </Typography>
              <Link href={"https://www.facebook.com/LJElectricalServices/"}>
                <img
                  src={fbLogo}
                  alt="Facebook Logo"
                  className={classes.img}
                />
              </Link>
            </Box>
          </Box>
        </Container>
        <Box bgcolor={blue[700]} className={classes.copyright}>
          <Container maxWidth="lg">
            <Grid
              bgcolor={blue[700]}
              container
              direction="row"
              justify="space-between"
              alignItems="center"
            >
              <Typography variant="subtitle1" color="textSecondary">
                L&J Electrical Services Ltd &copy;2019
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {"Designed by "}
                <Link color="inherit" href="https://eljay.xyz">
                  Eljay Software
                </Link>
              </Typography>
            </Grid>
          </Container>
        </Box>
      </footer>
      {/* End footer */}
    </React.Fragment>
  );
}
