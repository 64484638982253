const mytext = `# Customers
Breakdown help of the customer pages.

## New Customer (New)
This page allows you to create new customer. You will need to create a new customer whether you wish to carry out one job or one hundred jobs. 

Customers can have the following added to them:

- Lead
- Contracts
- Quotations
- Jobs
- Appointments

The fields you have to complete for a new customer will appear in red if you leave them blank. To clarify a few fields:

#### Main Contact
The main contact is the person usually at a business that you contact when managing work. i.e. the contract manager

#### Customer Name
This will usually be the individual name of the businesses name i.e. Mr S Smith or S Smith Building & Maintenance Ltd.

## Customer Page
The customer page gives you more information about the selected customer the page is split into three areas:

### Customer Details
This area displays information about the customer, you will see the information you entered when you created the customer and also the previous revenue over the past 12months and previous 12months.

You also have options to **Edit Customer**, **Create Quote** and **Create Job** for this customer. The last two options are for convenience and can be accessed easier through the side menu.

### Customer Graph
The customer graph displays the number of jobs for this customer over the past 3 years, each year is shown in a different colour and each is laid on the same axis, this will over time give an idea of the levels of work compared to previous years.

### Previous Jobs
Finally, this area lists previous work for the customer up to a limit of jobs. These jobs are always the latest jobs for the selected customer.

## Customers (Show All)
Once you have completed your first customer you can view all your customers on one page. This page will either display as a table or a list of cards depending on the screen size you are working on. 

On the customers page you have three buttons in the top right had corner:

- New Customer
- Show Contracts
- Help

Show **Show Contracts** and **New Customer** take you to the relevant pages and **Help** with give you a little help about this page.

Next there is a refresh button, this allows you to refresh the list if required. 

Then there is a filter dialogue which as you type will start to search the list of customers i.e. typing **s** will filter out all customers that do not have an **s** in the name.

Each customer has a **ID** number this is used to identify the customer and can be required at different point across Eljay, if asked for it you can find it here. 

For example, when uploading jobs using the bulk form the customer **ID** is required to assign the jobs to the right customer. 

Clicking on the **ID** or **View Customer** will take you to the Customer Page -- *See above*`;

export default mytext;