import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Markdown from "./Markdown";

import post from "./leads.md";
import Header from "./helpers/header";
import PageLink from "./helpers/createPageLink";

const useStyles = theme => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  toolbarTitle: {
    flex: 1
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    overflowX: "auto"
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0
  },
  mainFeaturedPost: {
    position: "relative",
    backgroundColor: theme.palette.grey[800],
    color: theme.palette.common.white,
    marginBottom: theme.spacing(4),
    backgroundImage: "url(https://lj-elec.co.uk/img/splash.png)",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right"
  },
  overlay: {
    position: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,.2)"
  },
  mainFeaturedPostContent: {
    position: "relative",
    padding: theme.spacing(3),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(6),
      paddingRight: 0
    }
  },
  mainGrid: {
    marginTop: theme.spacing(3)
  },
  card: {
    display: "flex"
  },
  cardDetails: {
    flex: 1
  },
  cardMedia: {
    width: 160
  },
  markdown: {
    ...theme.typography.body2,
    padding: theme.spacing(3, 0)
  },
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200]
  },
  sidebarSection: {
    marginTop: theme.spacing(3)
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0)
  }
});

const social = ["GitHub", "Twitter", "Facebook"];
// const classes = useStyles();

class Blog extends Component {
  render() {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <main>
            {/* Main featured post */}
            <Header
              title="Leads"
              subTitle="Work that might be..."
              // link={"Continue..."}
            />
            <Grid container spacing={5} className={classes.mainGrid}>
              {/* Main content */}
              <Grid item xs={12} md={8}>
                <Markdown
                  className={classes.markdown}
                  key={post.substring(0, 40)}
                >
                  {post}
                </Markdown>
              </Grid>
              {/* End main content */}
              {/* Sidebar */}
              <Grid item xs={12} md={4}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.sidebarSection}
                >
                  Sections
                </Typography>
                <PageLink title="Create Lead (New)" link="create-lead-new" />
                <PageLink
                  title="Use Customer Address (Button)"
                  link="use-customer-address-button"
                />
                <PageLink title="Leads (Show All)" link="leads-show-all" />

                <PageLink title="View Lead" link="view-lead" />
                <PageLink
                  title="Get External Leads (Button)"
                  link="get-external-leads-button"
                />

                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.sidebarSection}
                >
                  Social
                </Typography>
                {social.map(network => (
                  <Link display="block" variant="body1" href="#" key={network}>
                    {network}
                  </Link>
                ))}
              </Grid>
              {/* End sidebar */}
            </Grid>
          </main>
        </Container>
      </React.Fragment>
    );
  }
}

export default withStyles(useStyles)(Blog);
