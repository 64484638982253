const text = `# Leads
A lead is a potential job, leads can come from many different sources and can all added to Eljay. Also external leads can be added to Eljay through an external link. See more below.

## Create Lead (New)

When creating a new lead, the first field is for the **Customer**, if the **Lead** is for an existing customer then you can select them from the drop down list.

If the **Lead** is for a new **Customer** then you can leave the customer field blank and continue below.

### Use Customer Address (Button)
The Button located under the **Customer** field enters the customers address into the fields. This only an option as you may have a customer who has multiple addresses. 

The fields that are required to be entered to allow the creation of a lead are: 

1. Lead Source - where the lead come from i.e. Telephone Call or Word of Mouth.
2. Customer Name - name of the customer or occupier of the address.
3. Address Ln1 - First Line of Address
4. City
5. Postcode - A UK formatted postcode is required
6. Description of Works - A brief description of the work that is involved. 

Other fields that are not required but can be filled in to provide more information are:

1. Telephone number
2. Email Address
3. Does the Lead require a site Visit - This lets users looking at potential leads whether the potential lead requires a site visit, this is only a guide and could be changed later.
4. Estimated Start Time - Is a drop down list with options. This may help users to decide which leads to prioritize.
5. Rating - The rating is a slider, the idea is to give a feel how likely you feel you have at winning the work. When set to the furthest left would be 0% and set all the way to the right 100%. 

## Leads (Show All)

When on the **Leads** page you are presented with a table or a list of cards displaying all the open leads. Open leads are leads that have not progressed any further, there are three other states that a lead can be in these are as follows:

*Converted to Quote* - If a lead is in the progressed of being quoted for then the lead leaves the *Open Lead* section and enters the list to be quoted for.

*Converted to Job* - These leads have been either converted to a job, either straight from an *Open Lead* or from lead that was previously converted to a quote.

*Deleted Lead* - If a lead is not required or not interested in being pursued then it can be deleted and will appear in the list of deleted leads.

The table shows details about the lead, further details can be found by clicking the lead ID highlighted in blue or the **View Lead** button on smaller screens. 

## View Lead

When you create a lead or click on a lead from the **Show Leads** page you are taken to the **View Lead** page. There are buttons in the top left of screen for creating a new lead or viewing all the leads. Next is all the information on the lead i.e.

- Lead Id  
- Job Number
- Client 
- Customer Name
- Address
- Tel
- Email
- Time
- Source
- Probability of Work
- Quoted For
- Quotation Reference
- Site Visit Required
- Description of Works

All of these items are thing that were entered when the lead was created, apart from the following:

- *Lead Id* - Is incremental and is generated automatically when the lead was created
- *Job Number* - If the **Lead** is converted into **Job** then the job number is recorded here.
- *Quoted For* - If a quotation is generated from this **Lead** the this would display yes.
- *Quotation Reference* - If a quotation was generated then the quotation number would be recorded here. 

Following this there are a list of options these are as follows: 

- *Edit Lead* - Edit the lead to amend one of the above fields.
- *Create Site Visit* - Create an **Appointment** for this lead i.e. for a site visit. More on **Appointments** can be found [here](https://help.eljay.xyz/appointments).
- *Convert to Job* - Convert this lead to a job, the lead number will be recorded in the jobs details. 
- *Convert to Quote* - Convert this lead to a quote, the lead number will be recorded in the quotation and passed to the job if the quotation is successful.
- *Call Customer* - if on a mobile device the browser will open the phone dial pad and enter the telephone number.
- *Delete Lead* - Will delete the lead and move it to the deleted leads list. 

Under these buttons is a Refresh button, if a site visit i.e. and **Appointment** is booked then the appointment/site visit will appear here. 

## Get External Leads (Button)

> We thought that leads come from all different places and would most of the time need to be entered by hand into Eljay. But thought that if you have a contact us page on your website then leads could be automatically added to your account from your website. 

From the **Leads (Show All)** page at the top there is a button to **Get External Leads** clicking this link give an overview of the process to gather leads externally form a contact us page to be send to your eljay.xyz account. 

If you have a website which was built by a website developer giving them this information would allow them to send leads from your contact us page to eljay. We strongly recommend that your page has a **SSL** certificate (https://).

The request must be sent to the following url:

\`https://eljay.xyz/api/public/index.php/api/leads/external-source/new\` 

You must include your unique company identifier which can be found on the **Get External Leads** page, the following data can be sent. The items that are required are marked below all other items are optional.

\`\`\`
 "companyIdentifier": "YOUR COMPANY IDENTIFER", // String - Required - This is your companyIdentifier  
 "leadSource": "External", // String - Not Required - Defaults to 'External' 
 "firstName": "FIRST NAME", // String - Required 
 "lastName": "LAST NAME", // String - Required (Combined with first name to create a customer name)
 "address": "FIRST LINE OF ADDRESS", // String - Not required
 "town": "SECOND LINE/TOWN", // String - Not required
 "city": "CITY", // String - Not Required 
 "postalCode": "POSTCODE", // String - Not required  
 "telephoneNumber": "TELEPHONE NUMBER", // String - Not required
 "emailAddress": "EMAIL ADDRESS", // String - Not required - if send a notification will be sent as a confirmation
 "descriptionOfWork": "DESCRIPTION OF WORKS", // String - Required
 "estimatedStartTime": "ASAP", // String -Not required - Defaults to ASAP  
 "likelihood": "0.50" // Decimal - Not required - Defaults to 0.5
\`\`\`

**telephoneNumber** and **emailAddress** are not required but you should require one of these to allow you to be able to contact your potential customer. If you have problems with this feature please contact are help email address for further assistance [help@eljay.xyz](mailto:help@eljay.xyz).
`;

export default text;

